export default {
    path:'/simcard',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'simcard',
            component : () => import('@/views/simcard/index.vue')
        },
        // {
        //     path:'add',
        //     name:'perangkat.tambah',
        //     component : () => import('@/views/customer/create.vue')
        // },
        // {
        //     path:'edit/:id',
        //     name:'perangkat.ubah',
        //     component : () => import('@/views/customer/edit.vue')
        // },
        {
            path:'detail/:sim_id',
            name:'simcard.detail',
            component : () => import('@/views/simcard/detail.vue')
        },
    ]
}

